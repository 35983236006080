<template>
  <v-row dense>
    <v-col cols="12" md="6">
      <v-text-field
        disabled
        v-model="budget.validate_budget"
        label="Validade do orçamento"
        placeholder="07 Dias"
        rounded
        dense
        outlined
      />
    </v-col>
    <v-col cols="12" md="6">
      <!-- <v-select
        v-model="budget.deadline"
        label="Prazo de Entrega"
        :items="deadlines ? deadlines : getDeadlines()"
        :item-value="'name'"
        :item-text="'name'"
        rounded
        dense
        outlined
      /> -->
      <v-text-field
        v-model="budget.deadline"
        label="Prazo de Entrega"
        placeholder="07 Dias"
        disabled
        rounded
        dense
        outlined
      />
    </v-col>
    <v-col cols="12" md="6">
      <v-autocomplete
        v-model="budget.payment"
        label="Forma de pagamento"
        :items="paymentForms"
        :item-value="'name'"
        :item-text="'name'"
        disabled
        rounded
        dense
        outlined
      />
    </v-col>
    <v-col cols="12" md="6">
      <v-select
        v-model="budget.shipping"
        label="Frete"
        :items="shippings"
        :item-value="'name'"
        :item-text="'name'"
        disabled
        rounded
        dense
        hide-details
        outlined
      />
    </v-col>
    <v-col cols="12" md="12">
      <label class="subtitle-2 secondary--text">Descrição</label>
      
      <div rounded
        dense
        outlined
        auto-grow 
        v-html="budget.description" 
        />

    </v-col>
  </v-row>
</template>

<script>
export default {
  props: {
    budget: {
      type: Object,
      required: true,
    },
    paymentForms: Array,
    deadlines: Array,
    shippings: Array,
  },
};
</script>
